<template>
  <div class="banner-links block block--white block--rounded">
    <div class="block__title">
      Настройки меню под поиском
    </div>
    <draggable
      :list="localBannerLinks"
      group="bannerLinks"
      class="block__row"
      @change="changeSortOrder"
    >
      <div
        class="block__col"
        v-for="(button, index) in bannerLinks"
        :key="button.type"
      >
        <div
          class="banner-links__item"
        >
          <esmp-button
            class="banner-links__example"
            icon-position="left"
            :icon="BANNER_LINKS[button.type].icon"
            shape="circular"
            size="small"
            :style="{'background-color': button.backgroundColor}"
          >
            {{ button.title }}
          </esmp-button>
          <div class="banner-links__settings">
            <esmp-input
              :value="button.title"
              label="Введите название"
              @input="setValue('title', index, $event)"
            />
            <esmp-color-picker
              without-text-input
              class="banner-links__color-picker"
              :value="button.backgroundColor"
              @input="setValue('backgroundColor', index, $event)"
            />
          </div>
        </div>
      </div>
    </draggable>
    <div class="block__footer block__actions">
      <esmp-button
        view="outline"
        @click="save"
      >
        Сохранить
      </esmp-button>
      <esmp-button
        view="outline"
        @click="setDefault('bannerLinks')"
      >
        Вернуть базовые настройки
      </esmp-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import draggable from 'vuedraggable';
import { BANNER_LINKS } from '@/constants/sidebar';
import Hub from '@/plugins/event-hub';

export default {
  name: 'MetaData',
  components: {
    draggable,
  },
  data() {
    return {
      localBannerLinks: [],
    };
  },
  computed: {
    ...mapState('portalStyle', ['settings']),
    bannerLinks: {
      get() {
        return this.settings.common.bannerLinks;
      },
      set(newValue) {
        this.setSetting({
          path: ['common.bannerLinks'],
          value: newValue,
        });
      },
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('portalStyle', ['setSetting', 'saveSettings']),
    save() {
      this.setLoading({ key: 'page', value: true });
      this.saveSettings({ group: 'common', settingList: ['bannerLinks'] })
        .then(() => {
          this.$EsmpNotify.$show('Настройки меню под поиском успешно сохранены', 'success');
        })
        .finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
    setValue(fieldName, index, newValue) {
      const bannerLinks = cloneDeep(this.bannerLinks);
      bannerLinks[index][fieldName] = newValue;
      this.bannerLinks = bannerLinks;
    },
    changeSortOrder() {
      this.bannerLinks = this.localBannerLinks;
    },
    setDefault(section) {
      Hub.$emit('show-confirm-modal', section);
    },
  },
  created() {
    this.BANNER_LINKS = BANNER_LINKS;
    this.localBannerLinks = [...this.settings.common.bannerLinks];
  },
};
</script>

<style lang="scss">
.banner-links {
  .block__row {
    justify-content: flex-start;
    gap: 20px;
  }

  .block__col {
    width: calc(25% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: grab;
  }

  &__example {
    margin-bottom: $base-gutter;
  }

  &__settings {
    width: 100%;
    position: relative;
  }

  &__color-picker {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    z-index: 1;
  }
}
</style>
